function ExperienceItem(data) {
  const div = document.createElement('div');
  const img = document.createElement('img');
  const amount = document.createElement('span');
  const title = document.createElement('span');

  div.className = 'experience-item';
  div.setAttribute('data-aos', 'fade-down');

  img.src = data.img;
  img.alt = data.title;

  amount.textContent = data.amount;
  amount.className = 'amount';

  title.textContent = data.title;

  div.appendChild(img);
  div.appendChild(title);
  div.appendChild(amount);

  document.querySelector('.items').appendChild(div);

  return div.outerHTML;
}

export default ExperienceItem;
