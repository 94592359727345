function CustomerItem(data) {
  const div = document.createElement('div');
  div.className = 'swiper-slide';
  div.addEventListener('click', () => {
    window.open(data.link, 'mywindow');
  });

  const img = document.createElement('img');
  img.src = data.img;
  img.alt = 'aftabeshafa-customer-img';

  div.appendChild(img);

  document.querySelector('.swiper-wrapper').appendChild(div);

  return div.outerHTML;

  //   const markup = `
  //     <div class= swiper-slide onclick=window.open(${data.link},'mywindow') >
  //         <img src=${data.img} alt='aftabeshafa-customer-img'/>
  //     </div>
  //     `;

  //   return markup;
}

export default CustomerItem;
