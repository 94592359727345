function ServiceItem(data, index) {
  let image = data.img;

  const div = document.createElement('div');
  div.setAttribute('data-aos', 'fade-up');
  div.addEventListener('mouseover', () => {
    const elem = document.querySelector(`.service-img-${index}`);
    if (elem) {
      elem.src = data.img_hover;
    }
  });
  div.addEventListener('mouseout', () => {
    const elem = document.querySelector(`.service-img-${index}`);
    if (elem) {
      elem.src = data.img;
    }
  });
  div.className = 'service-item';
  div.id = `service-item-${index}`;

  const img = document.createElement('img');
  img.className = `service-img-${index}`;
  img.src = image;
  img.alt = data.title;

  const span = document.createElement('span');
  span.textContent = data.title;

  const button = document.createElement('button');
  button.className = 'btn btn-primary';
  button.textContent = 'مشاهده';

  button.addEventListener('click', () => {
    if (
      data.link.split('.').pop() === 'ir' ||
      data.link.split('.').pop() === 'com'
    ) {
      window.open(data.link, 'myWindow');
    } else {
      let url = window.location.toString();

      window.location = url.replace('pages/home', data.link);
    }
  });

  div.appendChild(img);
  div.appendChild(span);
  div.appendChild(button);

  document.querySelector('#service-container').appendChild(div);

  return div.outerHTML;

  // const markup = `
  //       <div class=service-item id=service-item-${index} >
  //           <img class='service-img-${index}' src=${image} alt=${data.title}  />
  //           <span>${data.title}</span>
  //           <button class='btn btn-primary'>مشاهده</button>
  //       </div>
  //   `;

  // return markup;
}

export default ServiceItem;
