import linkedIn from '../../../assets/social/linkedIn.svg';
import facebook from '../../../assets/social/facebook.svg';
import email from '../../../assets/social/email.svg';

function EmployeeItem(data) {
  const employeeItem = document.createElement('div');
  employeeItem.className = 'employee-item';
  employeeItem.setAttribute('data-aos', 'zoom-in');

  const topDiv = document.createElement('div');
  topDiv.className = 'top-div';

  const avatarDiv = document.createElement('div');
  avatarDiv.className = 'avatar-div';

  const avatar = document.createElement('img');
  avatar.src = data.avatar;
  avatar.alt = data.name;
  avatar.className = 'avatar';

  const name = document.createElement('span');
  name.textContent = data.name;
  name.className = 'name';

  const specialty = document.createElement('span');
  specialty.className = 'specialty';
  specialty.textContent = data.specialty;

  const education = document.createElement('span');
  education.textContent = data.education;

  const socialDiv = document.createElement('div');
  socialDiv.className = 'social-div';

  const linkedInDiv = document.createElement('div');
  const emailDiv = document.createElement('div');
  const facebookDiv = document.createElement('div');

  linkedInDiv.addEventListener('click', (e) => {
    window.open(data.social_links[0], 'myWindow');
  });

  const linkedInIcon = document.createElement('img');
  const emailIcon = document.createElement('img');
  const facebookIcon = document.createElement('img');

  linkedInDiv.className = 'linkedIn';
  facebookDiv.className = 'facebook';
  emailDiv.className = 'email';

  linkedInIcon.src = linkedIn;
  facebookIcon.src = facebook;
  emailIcon.src = email;

  linkedInIcon.alt = 'linkedIn';
  facebookIcon.alt = 'facebook';
  emailIcon.alt = 'email';

  socialDiv.appendChild(linkedInDiv);
  socialDiv.appendChild(emailDiv);
  socialDiv.appendChild(facebookDiv);

  linkedInDiv.appendChild(linkedInIcon);
  facebookDiv.appendChild(facebookIcon);
  emailDiv.appendChild(emailIcon);

  employeeItem.appendChild(topDiv);
  topDiv.appendChild(avatarDiv);
  avatarDiv.appendChild(avatar);
  employeeItem.appendChild(name);
  employeeItem.appendChild(specialty);
  employeeItem.appendChild(education);
  employeeItem.appendChild(socialDiv);

  document.querySelector('#employee-container').appendChild(employeeItem);
  return employeeItem.outerHTML;
}

export default EmployeeItem;
